import type { IndexedDBProps } from 'react-indexed-db';

const DBConfig: IndexedDBProps = {
  name: 'Ouaistern',
  version: 1,
  objectStoresMeta: [
    {
      store: 'rooms',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: false } },
        { name: 'name', keypath: 'name', options: { unique: false } },
        { name: 'users', keypath: 'users', options: { unique: false } },
        { name: 'messages', keypath: 'messages', options: { unique: false } },
      ],
    },
    {
      store: 'messages',
      storeConfig: { keyPath: 'id', autoIncrement: true },
      storeSchema: [
        { name: 'id', keypath: 'id', options: { unique: false } },
        { name: 'content', keypath: 'content', options: { unique: false } },
        { name: 'createdAt', keypath: 'createdAt', options: { unique: false } },
        { name: 'room', keypath: 'room', options: { unique: false } },
        { name: 'sender', keypath: 'sender', options: { unique: false } },
        { name: 'sync', keypath: 'sync', options: { unique: false } },
      ],
    },
  ],
};

export default DBConfig;
