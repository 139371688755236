import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/register.module.css';
import { useUser } from '../contexts/userProvider';
import { useSnackbar } from '../contexts/snackbarProvider';

function Register() {
  const { register } = useUser();
  const { newSnackbar } = useSnackbar();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState({
    name: '',
    username: '',
    email: '',
    password: '',
  });
  const [error, setError] = useState('');

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (
      !credentials.name
      || !credentials.email
      || !credentials.password
      || !credentials.username
    ) {
      setError(t('register.error.empty') || '');
      newSnackbar(t('register.error.empty'), 'ERROR', 5);
      return;
    }
    const res = await register(credentials);
    if (res?.error) {
      setError(res.error);
      newSnackbar(res.error, 'ERROR', 5);
    } else if (res?.success) {
      setError(t('register.success') || '');
      newSnackbar(t('register.success'), 'INFO', 3);
      navigate('/chat');
    }
  }

  return (
    <div className={styles.register}>
      <div className={styles.container}>
        <h1 className="title">{t('register.title') || ''}</h1>
        <form
          onSubmit={handleSubmit}
          className={styles.input_container}
          noValidate
        >
          <input
            className="input"
            type="text"
            placeholder={t('register.name') || ''}
            name="name"
            onChange={handleChange}
            value={credentials.name || ''}
          />
          <input
            className="input"
            type="text"
            placeholder={t('register.username') || ''}
            name="username"
            onChange={handleChange}
            value={credentials.username || ''}
          />
          <input
            className="input"
            type="email"
            placeholder={t('register.email') || ''}
            name="email"
            onChange={handleChange}
            value={credentials.email || ''}
          />
          <input
            className="input"
            type="password"
            placeholder={t('register.password') || ''}
            name="password"
            onChange={handleChange}
            value={credentials.password || ''}
          />
          {error && <span className={styles.error}>{error}</span>}
          <button
            type="submit"
            className="button is-primary"
            style={{ marginTop: '1rem' }}
          >
            {t('register.button')}
          </button>
        </form>
      </div>
    </div>
  );
}

export default Register;
