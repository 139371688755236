import { createRoot } from 'react-dom/client';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import axios from 'axios';
import { SnackbarProvider } from './contexts/snackbarProvider';
import { UserProvider } from './contexts/userProvider';
import './index.css';
import './i18n';

import App from './App';
import Register from './routes/register';
import Login from './routes/login';
import Chat from './routes/chat';

axios.defaults.withCredentials = true;

const rootElement = document.getElementById('root');
const root = createRoot(rootElement!);
root.render(
  <SnackbarProvider>
    <UserProvider>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<App />} />
          <Route path="register" element={<Register />} />
          <Route path="login" element={<Login />} />
          <Route path="chat" element={<Chat />} />
        </Routes>
      </BrowserRouter>
    </UserProvider>
  </SnackbarProvider>,
);
