import axios from 'axios';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './CreateRoom.module.css';
import handleError from '../../../lib/handleError';
import Popup from '../../Popup';
import config from '../../../config';

type RoomType = {
  name: string;
  users: string;
};

function CreateRoom() {
  const [isOpen, setIsOpen] = useState(false);
  const [room, setRoom] = useState<RoomType>({ name: '', users: '' });
  const [error, setError] = useState<string>('');
  const { t } = useTranslation();

  function handleOpen() {
    setIsOpen(!isOpen);
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRoom({ ...room, [e.target.name]: e.target.value });
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!navigator.onLine) return;
    if (!room.users) {
      setError(t('rooms.error.empty') || 'please add users to the room');
      return;
    }
    const res = await axios
      .put(`${config.apiUrl}/rooms/create`, {
        name: room.name,
        users: room.users.split(' '),
      })
      .catch((err) => err);
    if (res.status?.toString().startsWith('2')) {
      setRoom({ name: '', users: '' });
      setIsOpen(false);
      setError('');
    }
    if (res.response?.data?.code) {
      setError(
        handleError(t, res.response.data.code, res.response.data.message),
      );
    }
  }

  return (
    <>
      <button type="button" className="button is-link" onClick={handleOpen}>
        {t('rooms.create')}
      </button>
      <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
        <form onSubmit={handleSubmit}>
          <input
            className="input"
            type="text"
            placeholder={t('rooms.name') || 'Room name'}
            name="name"
            onChange={handleChange}
            value={room.name || ''}
          />
          <p>{t('rooms.users_paragraph')}</p>
          <p>JohnDoe John38 TheoMZ</p>
          <input
            className="input"
            type="text"
            placeholder={t('rooms.users') || 'Users to add'}
            name="users"
            onChange={handleChange}
            value={room.users || ''}
          />
          {error && <span className={styles.error}>{error}</span>}
          <button
            className="button is-primary"
            type="submit"
            style={{ margin: '0.9rem 0 0 0', width: '100%' }}
          >
            submit
          </button>
        </form>
      </Popup>
    </>
  );
}

export default CreateRoom;
