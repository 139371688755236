import { useEffect, useState } from 'react';
import { RoomType, MessageType } from '../../../types';

type RoomBtnPropsType = {
  name: RoomType['name'];
  users: RoomType['users'];
  messages: RoomType['messages']
};

function RoomBtn({ name, users, messages }: RoomBtnPropsType) {
  const roomName = users.length === 1 ? users[0].name : null;
  const [lastMessage, setLastMessage] = useState<MessageType | null>(null);

  useEffect(() => {
    setLastMessage(messages.length ? messages[messages.length - 1] : null);
  }, [messages]);

  return (
    <div
      className="title is-4"
      style={{
        width: '20rem',
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <h2
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textAlign: 'left',
          width: '320px',
        }}
      >
        {name === 'private' ? roomName || name : name}
      </h2>
      <p
        className="subtitle is-6"
        style={{
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textAlign: 'left',
          width: '320px',
          marginTop: '0.2rem',
          color: 'gray',
        }}
      >
        {lastMessage ? (
          <>
            <b>
              {lastMessage?.sender?.name.split(' ')[0]}
              {': '}
            </b>
            {lastMessage?.content}
          </>
        ) : 'be the first to send a message'}
      </p>
    </div>
  );
}

export default RoomBtn;
