import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { UserContextType, MessageType } from '../types';
import handleError from '../lib/handleError';
import {
  isEmailValid,
  isPasswordSecure,
  isUsernameValid,
} from '../lib/registerValidators';
import config from '../config';

const initContext = () => ({
  userID: null,
  isLogin: false,
  register: (credentials: any) => Promise.resolve(credentials),
  login: (credentials: any) => Promise.resolve(credentials),
  rooms: [],
  updateRoomsMessages: (message: MessageType) => Promise.resolve(message),
  loading: false,
  token: null,
});

const UserContext = React.createContext<UserContextType>(initContext());

export function UserProvider(props: any) {
  const { t } = useTranslation();
  const [userID, setUserID] = useState<string>('');
  const [isLogin, setIsLogin] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [token, setToken] = useState<string>('');

  useEffect(() => {
    async function fetchUserData() {
      const res = await axios
        .get(`${config.apiUrl}/users`)
        .catch((err) => err);
      if (res?.status?.toString().startsWith('2')) {
        setUserID(res.data.user.id);
        setToken(res.data.token);
        setIsLogin(true);
        setLoading(false);
      } else {
        setIsLogin(false);
        setLoading(false);
      }
    }

    if (loading) {
      fetchUserData();
    }
  }, [userID, loading]);

  const register = useCallback(
    async (credentials: {
      name: string;
      email: string;
      password: string;
      username: string;
    }) => {
      if (credentials.email && !isEmailValid(credentials.email)) {
        return { error: t('register.error.email'), status: true };
      }
      if (credentials.password && !isPasswordSecure(credentials.password)) {
        return { error: t('register.error.password'), status: true };
      }
      if (credentials.username && !isUsernameValid(credentials.username)) {
        return { error: t('register.error.username'), status: true };
      }
      const res = await axios
        .post(
          `${config.apiUrl}/users/register`,
          credentials,
        )
        .catch((err) => err);
      if (res.status?.toString().startsWith('2')) {
        setUserID(res.data.user.id);
        setToken(res.data.token);
        setIsLogin(true);
        return { success: true };
      }
      if (res.response?.data?.code) {
        return handleError(
          t,
          res.response.data.code,
          res.response.data.message,
        );
      }
      return { error: t('error.unknown'), status: true };
    },
    [t],
  );

  const login = useCallback(
    async (credentials: { login: string; password: string }) => {
      const res = await axios
        .post(
          `${config.apiUrl}/users/login`,
          credentials,
        )
        .catch((err) => err);
      if (res.status?.toString().startsWith('2')) {
        setUserID(res.data.user.id);
        setToken(res.data.token);
        setIsLogin(true);
        return { success: true };
      }
      setIsLogin(false);
      if (res.response?.data?.code) {
        return {
          error: handleError(
            t,
            res.response.data.code,
            res.response.data.message,
          ),
        };
      }
      return { error: { message: t('error.unknown'), status: true } };
    },
    [setUserID, setIsLogin, t],
  );

  const value = useMemo(
    () => ({
      register,
      login,
      userID,
      isLogin,
      loading,
      token,
    }),
    [register, login, userID, isLogin, loading, token],
  );
  /* eslint-disable-next-line react/jsx-props-no-spreading */
  return <UserContext.Provider {...props} value={value} />;
}

export const useUser = () => {
  const context: UserContextType = React.useContext(UserContext);
  if (!context) throw new Error('useUser must be used within a UserProvider');
  return context;
};
