import { useEffect, useState, useRef } from 'react';
import styles from './ChatBox.module.css';
import { useRoom } from '../../../contexts/roomsProvider';
import { RoomType } from '../../../types';
import Message from './Message';

function ChatBox() {
  const { rooms, room } = useRoom();
  const [currentRoom, setCurrentRoom] = useState<RoomType | null>(null);
  const messageEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setCurrentRoom(rooms.find((r) => r.id === room) || null);
  }, [rooms, room]);

  useEffect(() => {
    messageEndRef.current?.scrollIntoView({ });
  }, [currentRoom?.messages, messageEndRef]);

  return (
    <div className={styles.container}>
      {currentRoom?.messages
        && currentRoom.messages.map((message) => (
          <div key={message.id}>
            <Message message={message} />
          </div>
        ))}
      <div ref={messageEndRef} />
    </div>
  );
}

export default ChatBox;
