import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoom } from '../../../../contexts/roomsProvider';
import { RoomType } from '../../../../types';
import Popup from '../../../Popup';
import styles from './RoomOPTS.module.css';
import handleError from '../../../../lib/handleError';
import config from '../../../../config';

function DeleteRoom() {
  const { t } = useTranslation();
  const { rooms, room, handleRoomChange } = useRoom();

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [currentRoom, setCurrentRoom] = useState<RoomType | null>(null);

  useEffect(() => {
    setCurrentRoom(rooms.find((r) => r.id === room) || null);
  }, [rooms, room]);
  function handleIsOpen() {
    setIsOpen((prev) => !prev);
  }

  async function handleDelete() {
    if (!navigator.onLine || !currentRoom) return;
    const res = await axios
      .post(`${config.apiUrl}/rooms/delete`, {
        id: currentRoom.id,
      })
      .catch((err) => err);
    if (res.status?.toString().startsWith('2')) {
      setIsOpen(false);
      handleRoomChange(null);
    }
    if (res.response?.data?.code) {
      setError(
        handleError(t, res.response.data.code, res.response.data.message),
      );
    }
  }

  return (
    <>
      <button
        type="button"
        className={styles.button}
        onClick={handleIsOpen}
      >
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA4UlEQVR4nO2aSw7CIBRFz6iLUren1W3JwLiPttvASDAhJi9tlRbS3pO8CaGfm8eZACC2SwPcgAHwI9UDbXymOq4TAnzXhQrp488dJsw9Jp2pDh9rqfmrUXUQ98O6X6rcVoLcc3Sm1Hr2ub+rIH/i1RGDXS2tJ/DIMF48iDVn7niKglioI2hpBeSIhRxBjgTkiIUcQY4E5IiFHEGOBOSIhRxBjgTkiIUcQY6Uc8QZZxhzx1O0iW2xq03sJfAKMnIZ4H3Qvxan+M0u50vbgqe555xBmhjm05k1qoshqrx4IxjhBYDU2PkqqpaWAAAAAElFTkSuQmCC"
          alt="trashcan"
          className={styles.icon_del}
        />
      </button>
      <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
        <h1 className="title">{t('DeleteRoom.title')}</h1>
        <p className="subtitle">{t('DeleteRoom.subtitle')}</p>
        {error && <span className={styles.error}>{error}</span>}
        <button
          type="button"
          className="button is-danger"
          onClick={handleDelete}
        >
          {t('DeleteRoom.delete')}
        </button>
      </Popup>
    </>
  );
}

export default DeleteRoom;
