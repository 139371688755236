import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import styles from '../styles/login.module.css';
import { useUser } from '../contexts/userProvider';
import { useSnackbar } from '../contexts/snackbarProvider';

function Login() {
  const { t } = useTranslation();
  const { newSnackbar } = useSnackbar();
  const { login } = useUser();
  const navigate = useNavigate();
  const [credentials, setCredentials] = useState<{
    login: string;
    password: string;
  }>({
    login: '',
    password: '',
  });
  const [error, setError] = useState<string>('');

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { name, value } = event.target;
    setCredentials((prevCredentials) => ({
      ...prevCredentials,
      [name]: value,
    }));
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!credentials.login || !credentials.password) {
      setError(t('login.error.empty') || 'please fill all fields');
      newSnackbar(t('login.error.empty'), 'ERROR', 5);
      return;
    }

    const res = await login(credentials);
    if (res?.error) {
      newSnackbar(res.error, 'ERROR', 5);
      setError(res.error);
    } else if (res?.success) {
      setError(t('login.success') || '');
      newSnackbar(t('login.success'), 'INFO', 3);
      navigate('/chat');
    }
  }

  return (
    <div className={styles.login}>
      <div className={styles.container}>
        <h1 className="title">{t('login.title') || ''}</h1>
        <form
          onSubmit={handleSubmit}
          className={styles.input_container}
          noValidate
        >
          <input
            className="input"
            type="text"
            placeholder={t('login.login') || ''}
            name="login"
            onChange={handleChange}
            value={credentials.login || ''}
          />
          <input
            className="input"
            type="password"
            placeholder={t('login.password') || ''}
            name="password"
            onChange={handleChange}
            value={credentials.password || ''}
          />
          {error && <span className={styles.error}>{error}</span>}
          <button
            className="button is-primary"
            type="submit"
            style={{ marginTop: '1rem' }}
          >
            {t('login.button') || ''}
          </button>
        </form>
        <p>
          {t('login.notregistered')}
          <a href="/register">{t('login.here')}</a>
        </p>
      </div>
    </div>
  );
}

export default Login;
