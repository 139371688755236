import {
  ReactNode,
  createContext,
  useState,
  useEffect,
  useCallback,
  useMemo,
  useContext,
} from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import type { Socket } from 'socket.io-client';
import { io } from 'socket.io-client';
import axios from 'axios';
import { useSnackbar } from './snackbarProvider';
import handleError from '../lib/handleError';
import isIOS from '../utils/isios';

import type { MessageType, RoomType } from '../types';
import config from '../config';

interface ProviderPropsType {
  children: ReactNode;
  token: string | null;
}

interface ContextType {
  rooms: RoomType[];
  room: string | null;
  handleRoomChange: (id: string | null) => void;
  handleSendMessage: (message: string) => void;
  socket: Socket | null;
}

const RoomContext = createContext<ContextType | null>(null);

export function RoomProvider(props: ProviderPropsType) {
  const { t } = useTranslation();
  const { children, token }: ProviderPropsType = props;
  const navigate = useNavigate();
  const { newSnackbar } = useSnackbar();
  const [socket, setSocket] = useState<Socket | null>(null);
  const [isListeningEvents, setIsListeningEvents] = useState<boolean>(false);
  const [rooms, setRooms] = useState<RoomType[]>([]);
  const [room, setRoom] = useState<string | null>(null);

  // -- Users events -- //
  const handleRoomChange = useCallback((id: string | null) => {
    if (rooms.find((r) => r.id === id)) setRoom(id);
  }, [rooms]);

  const handleSendMessage = useCallback((message: string) => {
    // TODO: handle potential errors in the call back -> (response: any) => {}
    socket?.emit('message:create', { room, message }, (res : any) => {
      if (res?.success === false) {
        newSnackbar(
          res?.message || t('error.unknown'),
          'ERROR',
          5,
        );
      }
    });
  }, [room, socket, newSnackbar, t]);

  // -- Socket events -- //
  const handleNewMessageEvent = useCallback(async (newMessage: MessageType) => {
    setRooms((prevRooms: RoomType[]) => (
      prevRooms?.map((r) => (
        (r.id === newMessage.room.id ? {
          ...r, messages: [...r.messages, newMessage],
        } : r)
      ))
    ));

    if (
      !isIOS()
      && Notification
      && await Notification.permission === 'granted'
      && window.document.hidden
    ) {
      const notification = new Notification(
        `Ouaistern - ${newMessage.sender.name}`,
        { body: newMessage.content },
      );
      notification.onclick = () => {
        navigate(`?room=${newMessage.room.id}`);
        window.focus();
        notification.close();
      };
    }
  }, [setRooms, navigate]);

  const handleNewRoomEvent = useCallback((newRoom: RoomType) => {
    setRooms((prevRooms: RoomType[]) => ((prevRooms ? [...prevRooms, newRoom] : [newRoom])));
  }, [setRooms]);

  const handleRoomNameUpdateEvent = useCallback((updatedRoom: RoomType) => {
    setRooms((prevRooms: RoomType[]) => (
      prevRooms?.map((r) => (
        r.id === updatedRoom.id ? { ...r, name: updatedRoom.name } : r
      ))
    ));
  }, [setRooms]);

  const handleRoomUsersUpdateEvent = useCallback((updatedRoom: RoomType) => {
    setRooms((prevRooms: RoomType[]) => (
      prevRooms?.map((r) => (
        r.id === updatedRoom.id ? { ...r, users: updatedRoom.users } : r
      ))
    ));
  }, [setRooms]);

  const handleRoomDeleteEvent = useCallback((deletedRoom: RoomType) => {
    setRooms((prevRooms: RoomType[]) => prevRooms?.filter((r) => r.id !== deletedRoom.id));
  }, [setRooms]);

  useEffect(() => {
    if (!rooms.find((r) => r.id === room)) setRoom(null);
  }, [rooms, room]);

  // -- Fetch rooms on startup -- //
  useEffect(() => {
    (async () => {
      const res = await axios.get(`${config.apiUrl}/rooms/messages`).catch((e) => e);
      if (res?.status?.toString().startsWith('2')) {
        const { data }: { data: { rooms: RoomType[] } } = res;
        if (data) {
          setRooms(data.rooms);
        }
      } else if (res.response?.data?.code) {
        newSnackbar(
          handleError(t, res.response.data.code, res.response.data.message),
          'ERROR',
          3000,
        );
      }
    })();
    // Check if browser supports notifications
    if (
      !isIOS()
      && Notification
      && Notification.permission === 'default'
    ) Notification?.requestPermission();
  }, [newSnackbar, t]);

  // -- Listen to socket events -- //
  useEffect(() => {
    if (!socket && token) {
      const s = io(`${config.apiUrl}`, {
        query: { token },
      });
      setSocket(s);
      setInterval(() => {
        s?.emit('ping', () => {}).timeout(10000);
      }, 15000);
    }
    if (!isListeningEvents && socket) {
      setIsListeningEvents(true);
      socket.on('message:new', handleNewMessageEvent);
      socket.on('room:new', handleNewRoomEvent);
      socket.on('room:update:name', handleRoomNameUpdateEvent);
      socket.on('room:update:users', handleRoomUsersUpdateEvent);
      socket.on('room:delete', handleRoomDeleteEvent);
    }
  }, [
    socket,
    isListeningEvents,
    handleNewMessageEvent,
    handleNewRoomEvent,
    handleRoomNameUpdateEvent,
    handleRoomUsersUpdateEvent,
    handleRoomDeleteEvent,
    token,
  ]);

  const context = useMemo(
    () => ({
      rooms,
      room,
      handleRoomChange,
      handleSendMessage,
      socket,
    }),
    [rooms, room, handleRoomChange, handleSendMessage, socket],
  );

  return <RoomContext.Provider value={context}>{children}</RoomContext.Provider>;
}

export function useRoom() {
  const ctx = useContext(RoomContext);
  if (!ctx) throw new Error('useRoom must be used in RoomProvider');
  return ctx;
}
