import {
  ReactNode,
  useState,
  createContext,
  useContext,
  useMemo,
  useCallback,
} from 'react';
import styles from '../styles/Snackbar.module.css';

interface ProviderPropsType {
  children: ReactNode;
}

type SnackbarType = {
  message: string;
  type: 'ERROR' | 'INFO';
  duration: number;
  id?: string;
};

interface ContextType {
  newSnackbar: (
    message: SnackbarType['message'],
    type: SnackbarType['type'],
    duration: SnackbarType['duration'],
  ) => void;
}

const SnackbarContext = createContext<ContextType | null>(null);

function SnackBar({ snackbar: { message, type } } : { snackbar : SnackbarType }) {
  const [isHidden, setIsHidden] = useState(false);
  const typesStyle = [
    { type: 'ERROR', style: 'is-danger' },
    { type: 'INFO', style: 'is-info' },
  ];

  function handleClickEvent() {
    setIsHidden((state) => !state);
  }

  return (
    <span
      className={styles.animate}
      style={{
        whiteSpace: '-moz-pre-wrap',
        width: 'fit-content',
        minWidth: '50%',
        marginBottom: '0.5rem',
        display: isHidden ? 'none' : 'block',
      }}
    >
      <div
        className={`notification ${typesStyle.filter((data) => data.type === type)[0].style}`}
      >
        <button type="button" className="delete" onClick={handleClickEvent}>close</button>
        {message}
      </div>

    </span>
  );
}

export function SnackbarProvider(props: ProviderPropsType) {
  const { children } : ProviderPropsType = props;
  const [snackbars, setSnackbars] = useState<SnackbarType[]>([]);

  const newSnackbar = useCallback((
    message: SnackbarType['message'],
    type: SnackbarType['type'],
    duration: SnackbarType['duration'],
  ) => {
    const snackbar = {
      message, type, duration,
    };
    const uid = Math.random().toString();

    setSnackbars((prevState: SnackbarType[]) => ([...prevState, { ...snackbar, id: uid }]));
    setTimeout(() => {
      setSnackbars((prevState) => prevState.filter((ps) => ps.id !== uid));
    }, snackbar.duration * 1000);
  }, []);

  const context = useMemo(
    () => ({
      newSnackbar,
    }),
    [newSnackbar],
  );
  return (
    <SnackbarContext.Provider value={context}>
      {children}
      <div className={styles.snackbar_wrapper}>
        {snackbars?.map((snackbar) => (
          <SnackBar key={snackbar.id} snackbar={snackbar} />
        ))}
      </div>
    </SnackbarContext.Provider>
  );
}

export function useSnackbar() {
  const ctx = useContext(SnackbarContext);
  if (!ctx) throw new Error('useRoom must be used in RoomProvider');
  return ctx;
}
