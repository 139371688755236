import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import Rooms from '../components/Rooms';
import { useUser } from '../contexts/userProvider';
import { RoomProvider } from '../contexts/roomsProvider';

import styles from '../styles/chat.module.css';

function Chat() {
  const { isLogin, loading, token } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (navigator.onLine) {
      if (!isLogin && !loading) {
        navigate('/login');
      }
    }
  }, [loading, isLogin, navigate]);

  return !loading ? (
    <RoomProvider token={token}>
      <div className={styles.wrapper}><Rooms /></div>
    </RoomProvider>
  ) : (<>loading messages history</>);
}

export default Chat;
