import { useTranslation } from 'react-i18next';
import styles from './Popup.module.css';

function Popup({
  children,
  isOpen,
  setIsOpen,
}: {
  children: React.ReactNode;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const { t } = useTranslation();

  function handleClose() {
    setIsOpen(false);
  }
  return isOpen ? (
    <div className={styles.popup_container}>
      <div className={styles.popup}>
        <button
          type="button"
          className="button is-danger is-outlined"
          onClick={handleClose}
          style={{ marginLeft: 'auto', marginBottom: '0.7rem' }}
        >
          <span>{t('close')}</span>
        </button>
        {children}
      </div>
    </div>
  ) : (
    <div />
  );
}

export default Popup;
