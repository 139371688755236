import axios from 'axios';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRoom } from '../../../../contexts/roomsProvider';
import { RoomType } from '../../../../types';
import Popup from '../../../Popup';
import styles from './RoomOPTS.module.css';
import handleError from '../../../../lib/handleError';
import config from '../../../../config';

function UpdateUsers() {
  const { t } = useTranslation();
  const { rooms, room } = useRoom();

  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState('');
  const [users, setUsers] = useState('');
  const [currentRoom, setCurrentRoom] = useState<RoomType | null>(null);

  useEffect(() => {
    setCurrentRoom(rooms.find((r) => r.id === room) || null);
  }, [rooms, room]);

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setUsers(e.target.value);
  }

  async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!navigator.onLine || !currentRoom) return;
    if (users.trim().length > 0 === false) {
      setError(t('UpdateUsers.error.empty') || 'please add users to the room');
      return;
    }
    const res = await axios
      .post(
        `${config.apiUrl}/rooms/update/users`,
        {
          id: currentRoom.id,
          users: users.split(' '),
        },
      )
      .catch((err) => err);
    if (res.status?.toString().startsWith('2')) {
      setUsers('');
      setError('');
      setIsOpen(false);
    }
    if (res.response?.data?.code) {
      setError(
        handleError(t, res.response.data.code, res.response.data.message),
      );
    }
  }

  return (
    <>
      <button
        type="button"
        className={styles.button}
        onClick={() => setIsOpen(true)}
      >
        <img
          src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEGElEQVR4nO2ZzW9UVRjGf20FikTBCDTRbRUpYECEgoKmCxdEl1KNJi6ULWHhQr4iCxaowI6PiIJKdeHCSjX+A678aFyZuPAjiBELgtRC3TFj3uQ5yZvJzLnn3ts7U5J5kpN27rznvec557yfA1100UU78RDwN1DPGJeBB5nHeCuBRBh7mQe4F+hv8vxnLXJbZO6IZCabfNcHLKBN2Az8A1zXri7R88e1wF+Ansh8W+wUUNNVRJuyB/gLuASsrprERuBGwxW5ApwBftfn3Ql6Dkn2R+A48Ic+1/TXiK6tisQGnYK96CywHfi6gdSnwF0JuuwEPnPzbgNjwKB027OrwKNzTcIUXnMket1364BXgacL6LW5zwID7lmvI3NNGzgneER3txmJqtADnNI7b8guS2GVfH47STQjMw1soSAWyoN0gkSza2ae8h4KoE9XqiYj7BReFhFzNIuLKtkjJeZR8sBc50FgAvhWw/7fDwzl0LNAcal0JtAvH3+7wbO0whrgq4T05IvEoLfDxaoQeAvjuJSZm4zhReA/Z5zvATuBYXmdUeBd4KZkZoHnM3S+LlkLuKUwqOhbl8+PkQhR+QNgeUR2uWRCJI+ReUZyF5UC5cIDuo+T7ipYFI5dp3ASZgOpeENzbilWtcLnbh2WlB4BHk55wU9u4pTyokUR+WAT58mPc5prjqAVzAW/JodRc0EyMxWalvCIXHCWdwq7ujI/D1YA/0pH7FQC1rlNvjuVSAoOOrsoivdzuNf7qiIyIdmXKI6d0jGeWNBVQuT7hIowC5ulw2wgC0uqIvKdZJ+kOIZzEOmvisgFyVo+VBSj0mFuNiWRrYTIAcl+RHGcy2HsfUWIpBQzayR7MzEXa4S57BnpyMq9+lwHJonIb054MiEf+lKyH5Mf5xMCYr+C8pRb13RKQFylRPGim/hhRH5ICaDJvZmDxH6XPMZOY9ytwzb5aN52kZWaW4FfE67aCy59GMuI8ivdSdRk7FmR/LLWUgpHpcw6JWSQmXU2c1aLHNYY1bNgE7MZJAzPlbi2LSu0lDR6yNlMbEwkXo8BFXVWry8rQ2SXXvxDRhu0GaF9ijPfaFzQs7zt0DGt4TAF0evaoFZydgqDsqUZ5Vq5sUhty7qKmU6gR2VuXURilWdyv9dccrtJnHCOwYJhKTwmYzOFp3PaShm8M5ckAra4Su5kA5kBuclYc6IV1gOvAJsadB5zledTzDGecDHgjIxwTO4xuNXxFr9kNUMIinXnGXfIHisjEbDN9aVCJLdr94lrdltelFoV/qm54erW20EiYLtOZka+PQSqra7zktW0CK2m0GFfJl0z2qjKSQTcDyyNZM4jCRWhyTZiqXR3HMHTpIy3mcdY7Qqz2Jhuxy+3ZbFQ/afYMJkuuuAOwP+xW4w/VF4+sQAAAABJRU5ErkJggg=="
          alt="settings"
          className={styles.icon_settings}
        />
      </button>
      <Popup isOpen={isOpen} setIsOpen={setIsOpen}>
        <h1 className="title">{t('UpdateUsers.title')}</h1>
        <form onSubmit={handleSubmit} className={styles.form}>
          <p className="subtitle">{t('UpdateUsers.subtitle')}</p>
          <input
            className="input"
            type="text"
            placeholder={t('rooms.users') || 'Users to add'}
            name="users"
            onChange={handleChange}
            value={users || ''}
          />
          {error && <span className={styles.error}>{error}</span>}
          <button
            type="submit"
            className="button is-primary"
            style={{ width: '100%' }}
          >
            {t('UpdateUsers.button')}
          </button>
        </form>
      </Popup>
    </>
  );
}

export default UpdateUsers;
