import React, { useState } from 'react';
import { useRoom } from '../../../../contexts/roomsProvider';
import styles from './MessageInput.module.css';

function MessageInput() {
  const [message, setMessage] = useState<string>('');
  const { handleSendMessage } = useRoom();

  function messageValidator() {
    return message.trim().length > 0;
  }

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    if (!navigator.onLine && !messageValidator()) return;
    handleSendMessage(message);
    setMessage('');
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setMessage(e.target.value);
  }

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <input
        className="input is-info"
        type="text"
        placeholder="Primary input"
        value={message}
        onChange={handleChange}
      />
      <button type="submit" className="button is-info">
        Send
      </button>
    </form>
  );
}

export default MessageInput;
