import { useSearchParams } from 'react-router-dom';
import React, { useEffect } from 'react';
import { RoomType } from '../../types';
import { useRoom } from '../../contexts/roomsProvider';
import styles from './Rooms.module.css';

import RoomBtn from './RoomBtn';
import RoomBox from './RoomBox';
import CreateRoom from './CreateRoom';

function Rooms() {
  const { rooms, room, handleRoomChange } = useRoom();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (searchParams.get('room')) { handleRoomChange(searchParams.get('room')); }
  }, [searchParams, handleRoomChange]);

  function handleClickEvent(e: React.MouseEvent<HTMLButtonElement>) {
    handleRoomChange(e.currentTarget.name);
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.rooms}>
        {rooms.map((r: RoomType) => (
          <button
            type="button"
            className={r.id === room ? 'button is-info is-light' : 'button'}
            key={r.id}
            name={r.id}
            onClick={handleClickEvent}
            style={{ height: 'fit-content ' }}
          >
            <RoomBtn name={r.name} users={r.users} messages={r.messages} />
          </button>
        ))}
        {
          navigator.onLine
            ? <CreateRoom />
            : <div />
        }
      </div>
      {room && <RoomBox />}
    </div>
  );
}

export default Rooms;
