export function isEmailValid(email: string) {
  return /^(\.|-|_|[a-zA-Z0-9])+@(\.|-|[a-zA-Z0-9])+\.[A-Za-z]+$/.test(email);
}

export function isPasswordSecure(password: string) {
  return /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})/.test(password);
}

export function isUsernameValid(username: string) {
  return /^([a-zA-Z0-9]|-|_|\.)*$/.test(username);
}
