import { useTranslation } from 'react-i18next';
import { useUser } from '../../../../contexts/userProvider';
import styles from './Message.module.css';
import { MessageType } from '../../../../types';

function Message({
  message: { content, sender, createdAt },
}: {
  message: MessageType;
}) {
  const { userID } = useUser();
  const { t } = useTranslation();

  function isSender() {
    return sender.id === userID;
  }

  function formatDate() {
    const now = new Date();
    const messageDate = new Date(createdAt);
    const diff = now.getTime() - messageDate.getTime();
    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `${days}${t('message.days')}`;
    }
    if (minutes > 0 || hours > 0) {
      const dateCreatedAt = new Date(createdAt);
      return `${dateCreatedAt
        .getHours()
        .toString()
        .padStart(2, '0')}:${dateCreatedAt
        .getMinutes()
        .toString()
        .padStart(2, '0')}`;
    }
    if (seconds > 0) {
      return `${seconds} ${t('message.seconds')}`;
    }
    return t('message.now');
  }

  return (
    <div>
      <div className={isSender() ? styles.right_msg : styles.msg_bubble}>
        <div className={styles.msg_info}>
          <div className={styles.msg_info_name}>{sender.name}</div>
          <div className={styles.msg_info_time}>{formatDate()}</div>
        </div>
        {content.startsWith('https://www.google.fr/maps/search/')
          ? (
            <div className={`${styles.msg_text} ${styles.position_link}`}>
              <a href={content} target="_blank" rel="noreferrer">
                {
                  t('message.location')
                }
              </a>
            </div>
          )
          : <div className={styles.msg_text}>{content}</div>}
      </div>
    </div>
  );
}

export default Message;
