import { useEffect, useState } from 'react';
import axios from 'axios';
import { RoomType } from '../../../types';
import styles from './RoomBox.module.css';

import { useRoom } from '../../../contexts/roomsProvider';

import MessageInput from '../ChatBox/MessageInput';
import ChatBox from '../ChatBox';
import DeleteRoom from './RoomOPTS/DeleteRoom';
import UpdateUsers from './RoomOPTS/UpdateUsers';
import SendGeolo from './RoomOPTS/SendGeolo';
import RoomsMenu from './RoomOPTS/RoomsMenu';
import config from '../../../config';

function RoomBox() {
  const { rooms, room } = useRoom();
  const [currentRoom, setCurrentRoom] = useState<RoomType | null>(null);
  const [roomName, setRoomName] = useState<string>('');

  useEffect(() => {
    setCurrentRoom(rooms.find((r) => r.id === room) || null);
  }, [rooms, room]);

  useEffect(() => {
    setRoomName(currentRoom?.name || '');
  }, [currentRoom]);

  function formatUsers(users: RoomType['users']) {
    return users.map((user) => user.name).join(', ');
  }

  function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
    setRoomName(e.target.value);
  }

  async function handleSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();

    if (!currentRoom) return;
    await axios.post(
      `${config.apiUrl}/rooms/update/name`,
      {
        id: currentRoom.id,
        name: roomName,
      },
    );
  }

  return (
    <div className={styles.chatbox}>
      <div className={styles.header}>
        <div id="header_infos">
          <form onSubmit={handleSubmit} noValidate>
            <input
              className="input is-large"
              type="text"
              placeholder="Room name"
              name="login"
              value={roomName}
              onChange={handleChange}
              style={{
                width: 'fit-content',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '500',
                fontSize: '2em',
              }}
            />
          </form>
          <div className="subtitle is-4">{formatUsers(currentRoom?.users || [])}</div>
        </div>

        {
            navigator.onLine
              ? (
                <div className={styles.header_buttons}>
                  <RoomsMenu />
                  <div className={styles.header_opts}>
                    <SendGeolo />
                    <UpdateUsers />
                    <DeleteRoom />
                  </div>

                </div>
              )
              : (
                <div>
                  You are offline
                </div>
              )
          }

      </div>
      <ChatBox />
      <MessageInput />
    </div>
  );
}

export default RoomBox;
