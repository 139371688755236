import { useSearchParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { RoomType } from '../../../../types';
import { useRoom } from '../../../../contexts/roomsProvider';
import styles from './RoomOPTS.module.css';

import RoomBtn from '../../RoomBtn/RoomBtn';
import CreateRoom from '../../CreateRoom';

function UpdateUsers() {
  const { rooms, room, handleRoomChange } = useRoom();
  const [searchParams] = useSearchParams();
  const [isOpen, setIsOpen] = useState(false);
  const [wasClicked, setWasClicked] = useState(false);

  useEffect(() => {
    if (searchParams.get('room')) handleRoomChange(searchParams.get('room'));
  }, [searchParams, handleRoomChange]);

  function onClickHandler(r: RoomType) {
    handleRoomChange(r.id);
    setIsOpen(false);
  }

  function handleOpen() {
    setIsOpen(true);
    setWasClicked(true);
  }

  return (
    <>
      <div className={styles.rooms_menu}>
        <button type="button" className="button is-ghost" onClick={handleOpen}>
          <img
            src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAAZUlEQVRoge3WMQrCQBRF0YurM+6/TkDch7ZW0SJhJnAO3H7+VK8A4DiPaqvek7dVy94hzwke+W+v74ff9q66sqVaG//bv1qr+0l/AABwFjN+QGb8TJnxAMAVmfEDMuNnyowH4FAf9JZ/1GMxO0wAAAAASUVORK5CYII="
            alt="menu"
            className={styles.icon}
          />
        </button>
      </div>
      {wasClicked && (
        <div
          className={
            isOpen ? styles.rooms_menu_slidein : styles.rooms_menu_slideout
          }
        >
          <div className={styles.rooms}>
            {rooms.map((r: RoomType) => (
              <button
                type="button"
                className={r.id === room ? 'button is-primary' : 'button'}
                key={r.id}
                onClick={() => onClickHandler(r)}
                style={{ height: 'fit-content ' }}
              >
                <RoomBtn name={r.name} users={r.users} messages={r.messages} />
              </button>
            ))}
            <CreateRoom />
          </div>
        </div>
      )}
    </>
  );
}

export default UpdateUsers;
