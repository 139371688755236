import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { initDB } from 'react-indexed-db';
import { useUser } from './contexts/userProvider';
import DBConfig from './lib/DBConfig';
import 'bulma/css/bulma.min.css';

initDB(DBConfig);

function App() {
  const { isLogin, loading } = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isLogin && !loading) {
      navigate('/login');
    } else if (isLogin && !loading) {
      navigate('/chat');
    }
  }, [loading, isLogin, navigate]);

  return (
    <div>
      <p>Waiting for yor redirection to the appropriate page</p>
    </div>
  );
}

export default App;
